import React from 'react';
import { Link } from 'gatsby';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { SiTiktok } from '@react-icons/all-files/si/SiTiktok';

import './footer.scss';
import logo from '../images/logo.png';

const Footer = (props) => {
    return (
        <footer
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-delay="300"
            data-sal-easing="ease"
        >
            <div className="top">
                <div className="left">
                    <div className="branding">
                        <img src={logo} />
                        <h5>Tukan</h5>
                    </div>
                    <p>
                        Planning a vacation should be exciting not overwhelming.
                        That's why we created a one-stop spot for all your
                        trip-related needs.
                    </p>
                </div>
                <div className="right">
                    <h6>Links</h6>
                    <ul>
                        <li>
                            <a href="mailto:info@tukantravels.com">Contact</a>
                        </li>
                        <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/terms-and-conditions">
                                Terms and Conditions
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <span className="llc">
                        © {new Date().getFullYear()} Odysee LLC.
                    </span>
                    <span>Made in Miami</span>
                </div>
                <div>
                    <a href="https://www.instagram.com/tukantravels/">
                        <FaInstagram
                            color="#ffffffc9"
                            size={30}
                            style={{ marginRight: 20 }}
                        />
                    </a>
                    <a href="https://www.tiktok.com/@tukantravels?_d=secCgYIASAHKAESPgo8ZTDo%2FSiQ9bNVKhNltpe5YFzh3i6JkExmCXsTzoZ9pSESxjek%2B21qHwQnJaDFUlTtXzWw9J7YFnISg4ybGgA%3D&_r=1&checksum=61f2876ef611849ed7ab184052407785d1d657726602d6f5b5ff1ca8d2b47f81&language=en&sec_uid=MS4wLjABAAAAwUhL8jwV96OPzCKhaGTtNoO53DTKXE8gcEAT06F8ew1p6XG_rh4qE793IZBV6LCH&sec_user_id=MS4wLjABAAAAwUhL8jwV96OPzCKhaGTtNoO53DTKXE8gcEAT06F8ew1p6XG_rh4qE793IZBV6LCH&share_app_id=1233&share_author_id=7096964097924793386&share_link_id=02DC2CAD-3ACB-477A-88E1-FB1883806E8A&source=h5_m&tt_from=copy&u_code=e1jaced9dij4hl&user_id=7096964097924793386&utm_campaign=client_share&utm_medium=ios&utm_source=copy">
                        <SiTiktok color="#ffffffc9" size={30} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
